import * as React from 'react';
import { InlineField, InlineFieldRow, InlineLabel, InputControl, Select } from '@grafana/ui';

import { AppContext } from '../../SimplePanel';
import { useFormPublicSettings } from '../../../hooks/useFormPublicSettings';
import { SubSectionSchedulingsActivationProps } from '../../../types/form';

export const SubSectionIntrospectionPeriod: React.FunctionComponent<SubSectionSchedulingsActivationProps> = ({
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
  control,
  setIsMainModalOpen,
  handlePeriodChange,
  isSync,
}) => {
  const { dico } = React.useContext(AppContext);
  const { HOURS, MINUTES, SECONDS } = useFormPublicSettings(setIsMainModalOpen);

  // Translations
  const { introspection } = dico?.dico || {};

  return (
    <InlineFieldRow width={'100%'}>
      <InlineField
        label={introspection}
        labelWidth={24}
        htmlFor="introspectionHours"
        invalid={!!errors.introspection_hours}
        error={errors.introspection_hours?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={HOURS}
              inputId="introspectionHours"
              invalid={invalid}
              onChange={(e) => setValue('introspection_hours', e.value)}
              width={12}
            />
          )}
          control={control}
          name="introspection_hours"
        />
      </InlineField>
      <InlineField shrink>
        <InlineLabel>h</InlineLabel>
      </InlineField>
      <InlineField
        labelWidth={24}
        htmlFor="introspectionMinutes"
        invalid={!!errors.introspection_minutes}
        error={errors.introspection_minutes?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={MINUTES}
              inputId="introspectionMinutes"
              onChange={(e) => setValue('introspection_minutes', e.value)}
              width={12}
              invalid={invalid}
            />
          )}
          control={control}
          name="introspection_minutes"
        />
      </InlineField>
      <InlineField shrink>
        <InlineLabel>min</InlineLabel>
      </InlineField>
      <InlineField
        labelWidth={24}
        htmlFor="introspectionSeconds"
        invalid={!!errors.introspection_seconds}
        error={errors.introspection_seconds?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={SECONDS}
              inputId="introspectionSeconds"
              onChange={(e) => setValue('introspection_seconds', e.value)}
              width={12}
              invalid={invalid}
            />
          )}
          control={control}
          name="introspection_seconds"
        />
      </InlineField>
      <InlineField shrink>
        <InlineLabel>s</InlineLabel>
      </InlineField>
    </InlineFieldRow>
  );
};
