import {
  Control,
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { TranslationProps } from './dictionary';

export interface SubSectionProps {
  clearErrors: UseFormClearErrors<SignatureFormDTO>;
  control: Control<SignatureFormDTO>;
  errors: DeepMap<SignatureFormDTO, FieldError>;
  getValues: UseFormGetValues<SignatureFormDTO>;
  register: UseFormRegister<SignatureFormDTO>;
  setError: UseFormSetError<SignatureFormDTO>;
  setValue: UseFormSetValue<SignatureFormDTO>;
  watch: UseFormWatch<SignatureFormDTO>;
  dico: TranslationProps;
}

export interface SubSectionSchedulingsActivationProps {
  clearErrors: UseFormClearErrors<GlobalPublicSettingsFormDTO>;
  control: Control<GlobalPublicSettingsFormDTO>;
  errors: DeepMap<GlobalPublicSettingsFormDTO, FieldError>;
  getValues: UseFormGetValues<GlobalPublicSettingsFormDTO>;
  setError: UseFormSetError<GlobalPublicSettingsFormDTO>;
  setValue: UseFormSetValue<GlobalPublicSettingsFormDTO>;
  setIsMainModalOpen: (value: boolean) => void;
  dico: TranslationProps;
  isSync?: boolean;
  handlePeriodChange: (
    currentValue: number,
    setValue: UseFormSetValue<GlobalPublicSettingsFormDTO>,
    getValues: UseFormGetValues<GlobalPublicSettingsFormDTO>,
    setError: UseFormSetError<GlobalPublicSettingsFormDTO>,
    clearErrors: UseFormClearErrors<GlobalPublicSettingsFormDTO>,
    name: PeriodName,
    syncName: PeriodName
  ) => void;
}

export interface SubSectionRadioProps extends SubSectionProps {
  defaultValues: SignatureFormDTO;
  dico: TranslationProps;
}

export type FFTProps = 'fft_freq_max' | 'fft_freq_min' | 'fft_cutoff' | 'steps';

/** FFT Zoom settings  */
export type FftFormDTO = {
  handle: number;
  activation: number;
  algorithm: number;
  steps: number;
  fft_compression: number;
  fft_cutoff: string;
  fft_freq_max: string;
  fft_freq_min: string;
  fft_sensor_orientation: number;
  fft_sensor_type: SensorType;
  fft_spectr_type: string;
};

export interface TimeProps {
  periodicity: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface IndicatorValueProps {
  enabled: boolean;
  bitPos: number;
}

/** General settings (scheduling and activation) */
export type PublicFormDTO = {
  ambient_periodicity: number;
  ambient_hours: number;
  ambient_minutes: number;
  ambient_seconds: number;
  prediction_periodicity: number;
  prediction_hours: number;
  prediction_minutes: number;
  prediction_seconds: number;
  introspection_periodicity: number;
  introspection_hours: number;
  introspection_minutes: number;
  introspection_seconds: number;
};

export interface IndicatorProps {
  Battery: IndicatorValueProps;
  Humidity: IndicatorValueProps;
  Temperature: IndicatorValueProps;
  Signature: IndicatorValueProps;
  Mileage: IndicatorValueProps;
  MachineDrift: IndicatorValueProps;
  WakeOnMotion: IndicatorValueProps;
  MachineR0: IndicatorValueProps;
  MachineR1: IndicatorValueProps;
  SonicDetection: IndicatorValueProps;
  Pt100: IndicatorValueProps;
  TC: IndicatorValueProps;
  CurrentLoop: IndicatorValueProps;
  Pressure: IndicatorValueProps;
  ShockDetection: IndicatorValueProps;
  SonicE: IndicatorValueProps;
  VibraE: IndicatorValueProps;
  AmbientAggr: IndicatorValueProps;
  Wav: IndicatorValueProps;
  LoraLink: IndicatorValueProps;
}

export interface GlobalPublicSettingsFormDTO extends PublicFormDTO {
  Battery: IndicatorValueProps;
  Humidity: IndicatorValueProps;
  Temperature: IndicatorValueProps;
  Signature: IndicatorValueProps;
  Mileage: IndicatorValueProps;
  MachineDrift: IndicatorValueProps;
  WakeOnMotion: IndicatorValueProps;
  MachineR0: IndicatorValueProps;
  MachineR1: IndicatorValueProps;
  SonicDetection: IndicatorValueProps;
  Pt100: IndicatorValueProps;
  TC: IndicatorValueProps;
  CurrentLoop: IndicatorValueProps;
  Pressure: IndicatorValueProps;
  ShockDetection: IndicatorValueProps;
  SonicE: IndicatorValueProps;
  VibraE: IndicatorValueProps;
  AmbientAggr: IndicatorValueProps;
  Wav: IndicatorValueProps;
  LoraLink: IndicatorValueProps;
}

export interface PublicSettingsProps {
  public: PublicFormDTO;
  indicators: IndicatorProps;
  receptionDate: string;
}

/**
 * Signature settings
 * NOTE: Input values must be string
 */

export type SignatureFormDTO = {
  woe_mode: string;
  woe_flag: string;
  woe_param: number;
  woe_profile: string;
  woe_threshold: number;
  woe_pretrig_threshold: number;
  woe_posttrig_threshold: number;
  rpm_max: number;
  rpm_min: number;
  threshold: number;
  learning_steps: number;
  sensor_type: SensorType;
  sensor_orientation: Orientation;
  accelero_freq_min: number;
  accelero_freq_max: number;
  micro_freq_min: number;
  micro_freq_max: number;
  custom_spectrum_type: string;
  custom_spectrum_param: number;
  radio_adr: boolean;
  radio_txack: boolean;
  radio_nw_private: boolean;
  radio_cr_base_lorawan: boolean;
  radio_dwell_time: boolean;
  radio_retx_twice: boolean;
  radio_force_lowest_dr: boolean; // enable_packet_split
  radio_region_param: number;
  radio_linkchk: number;
};

export type P = keyof SignatureFormDTO;

/** Scheduling time options */
export type PeriodName =
  | 'ambient_minutes'
  | 'ambient_hours'
  | 'ambient_periodicity'
  | 'ambient_seconds'
  | 'prediction_minutes'
  | 'prediction_hours'
  | 'prediction_periodicity'
  | 'prediction_seconds';

/** Frequency signature settings  */
export type FrequencyName = 'accelero_freq_min' | 'accelero_freq_max' | 'micro_freq_min' | 'micro_freq_max';

export type SignatureParameter =
  | 'woe_mode'
  | 'woe_flag'
  | 'woe_param'
  | 'woe_threshold'
  | 'woe_pretrig_threshold'
  | 'woe_posttrig_threshold'
  | 'rpm_max'
  | 'rpm_min'
  | 'threshold'
  | 'learning_steps'
  | 'sensor_type'
  | 'sensor_orientation'
  | 'accelero_freq_min'
  | 'accelero_freq_max'
  | 'micro_freq_min'
  | 'micro_freq_max'
  | 'custom_spectrum_type'
  | 'custom_spectrum_param'
  | 'radio_adr'
  | 'radio_txack'
  | 'radio_nw_private'
  | 'radio_cr_base_lorawan'
  | 'radio_dwell_time'
  | 'radio_retx_twice'
  | 'radio_force_lowest_dr'
  | 'radio_region_param'
  | 'radio_linkchk';

export type ActivationParameter =
  | 'Battery'
  | 'Humidity'
  | 'Temperature'
  | 'Signature'
  | 'Mileage'
  | 'MachineDrift'
  | 'WakeOnMotion'
  | 'MachineR0'
  | 'MachineR1'
  | 'SonicDetection'
  | 'Pt100'
  | 'TC'
  | 'CurrentLoop'
  | 'Pressure'
  | 'ShockDetection'
  | 'SonicE'
  | 'VibraE'
  | 'AmbientAggr'
  | 'Wav'
  | 'LoraLink';

export type PublicSettingsTimeKey = 'periodicity' | 'hours' | 'minutes' | 'seconds';
export type PublicSettingKey = 'ambient' | 'prediction' | 'introspection';

/** FFT Frequency  */
export type FrequencyFFTName = 'fft_freq_min' | 'fft_freq_max';

export enum Orientation {
  X = '010000',
  Y = '020000',
  Z = '040000',
  XYZ = '000000',
}

export enum SensorType {
  microphone = '12',
  accelerometer = '3',
}

/** Wake on events modes */
export enum WoeMode {
  inactive = 'Inactive',
  motion = 'Wake-On-Motion',
  reserved = 'RESERVED',
  scheduler = 'Wake-On-Scheduler',
  analog = 'Wake-On-Analog',
  contact = 'Wake-On-Contact',
}
