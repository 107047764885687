import React from 'react';
import { PanelProps, OrgRole } from '@grafana/data';
import { getTemplateSrv, config, locationService } from '@grafana/runtime';
import { Button, Modal, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { SectionPublicSettings, SectionSignatureSettings, SectionFftZoomSettings } from './settingsSections';
import { BeaconVersion } from './settingsSections/BeaconVersion';
import { useDictionary } from 'hooks/useDictionary';
import { AppState } from 'types/state';
import { useVersion } from 'hooks/useVersion';
import { useDatasource } from 'hooks/useDatasource';
import { getClientInfo } from 'utils/helpers';
import { AdvancedQueries } from './adminQueries/AdvancedQueries';

interface Props extends PanelProps {}

export const AppContext = React.createContext({} as AppState);

export const SimplePanel: React.FunctionComponent<Props> = ({ data, width, height }) => {
  const [isMainModalOpen, setIsMainModalOpen] = React.useState(false);
  const [isVersionFound, setIsVersionFound] = React.useState(true);

  const dicoData = useDictionary();
  const datasource = useDatasource();
  const version = useVersion(data);

  React.useEffect(() => {
    if (version.raw === 0.0 || !version.displayed) {
      setIsVersionFound(false);
    }
  }, [version]);

  const styles = useStyles2(getStyles);
  const beacon = getTemplateSrv()?.replace('$beacon_selection'); // trick to retrieve mac address
  const clientInfo = getClientInfo(data);
  const canUseFftZoom = version?.raw >= 4.34;
  const isDeprecatedVersion = version?.raw <= 4.11;
  const user = config.bootData.user;
  const isEditing = locationService.getSearchObject().editPanel !== undefined;

  // Context state
  const appState = {
    dico: dicoData,
    panelData: data,
    beacon: beacon,
    client: clientInfo.client,
    gateway: clientInfo.gateway,
    datasource: datasource,
    isVersionFound: isVersionFound,
    setIsVersionFound: setIsVersionFound,
    user: config.bootData.user,
  };

  // Translations
  const { beaconSettings } = dicoData.dico;
  const beacon_settings = beaconSettings?.toUpperCase();

  const handleClick = () => {
    setIsMainModalOpen(!isMainModalOpen);
  };

  return (
    <AppContext.Provider value={appState}>
      {!isEditing && (
        <div className={styles.topShift}>
          {user.orgRole !== OrgRole.Viewer && (
            <>
              <Button
                variant="secondary"
                onClick={handleClick}
                data-testid={'beacon_settings_button'}
                name={'beacon settings'}>
                {beacon_settings}
              </Button>
              <Modal isOpen={isMainModalOpen} title={beacon_settings} onDismiss={handleClick} className={styles.modal}>
                <div data-testid={'modal_content'}>
                  <BeaconVersion version={version.displayed} />
                  <SectionPublicSettings setIsMainModalOpen={setIsMainModalOpen} />
                  {!isDeprecatedVersion && <SectionSignatureSettings />}
                  {canUseFftZoom && <SectionFftZoomSettings />}
                </div>
              </Modal>
            </>
          )}
        </div>
      )}
      {isEditing && <AdvancedQueries width={width} height={height} />}
    </AppContext.Provider>
  );
};

const getStyles = () => {
  return {
    topShift: css`
      margin-top: 8px;
    `,
    modal: css`
      width: 80%;
      max-width: 980px;
      top: 20px;
      max-height: 95%;
      height: 90%;
      z-index: 1050;
    `,
  };
};
