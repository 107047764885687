import { Button } from '@grafana/ui';
import { AppContext } from 'components/SimplePanel';
import * as React from 'react';

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
    } catch (error) {
      setIsCopied(false);
      console.error('Unable to copy to clipboard:', error);
    }
  };

  return { isCopied, copyToClipboard };
};

interface CopyToClipboardButtonProps {
  content: string;
}

const CopyToClipboardButton: React.FunctionComponent<CopyToClipboardButtonProps> = ({ content }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const { dico } = React.useContext(AppContext);
  const { copy, copied } = dico?.dico || {};

  return (
    <div>
      <Button fill="text" size="sm" onClick={() => copyToClipboard(content)}>
        {isCopied ? copied : copy}
      </Button>
    </div>
  );
};

export default CopyToClipboardButton;
