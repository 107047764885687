import React from 'react';
import { Button, HorizontalGroup, useStyles2, useTheme2, ConfirmModal } from '@grafana/ui';
import { getStyles } from '../getSectionsStyles';
import { AppContext } from 'components/SimplePanel';
import { useFormPublicSettings } from 'hooks/useFormPublicSettings';

/**
 * Scheduling and activation section
 * @returns ambient period field
 * @returns prediction period field
 * @returns sync field
 */

interface SectionRebootProps {
  setIsMainModalOpen: (value: boolean) => void;
}

export const SectionReboot: React.FunctionComponent<SectionRebootProps> = ({ setIsMainModalOpen }) => {
  const { dico } = React.useContext(AppContext);

  const { handleConfirmModal, setShowConfirmModalReboot, showConfirmModalReboot, sendSpecialCommand } =
    useFormPublicSettings(setIsMainModalOpen);

  // Translations
  const { titleSpecialCommands, buttonRequestReboot, reboot, confirmReboot, sendRebootButton } = dico?.dico || {};

  const theme = useTheme2();
  const styles = useStyles2(() => getStyles(theme));

  return (
    <div className={styles.sectionHorizontal}>
      <div className={styles.sectionTitle}>
        <h4>{titleSpecialCommands?.toUpperCase()}</h4>
      </div>
      <div className={styles.commandsContainer}>
        <HorizontalGroup spacing={'sm'} justify={'space-between'} align={'center'}>
          {/* Request Reboot */}
          <Button variant={'destructive'} size={'md'} icon={'sync'} onClick={() => handleConfirmModal('reboot')}>
            {buttonRequestReboot}
          </Button>
          <ConfirmModal
            isOpen={showConfirmModalReboot}
            title={reboot}
            body={confirmReboot}
            confirmText={sendRebootButton}
            onConfirm={() => sendSpecialCommand('reboot')}
            onDismiss={() => setShowConfirmModalReboot(false)}
          />
        </HorizontalGroup>
      </div>
    </div>
  );
};
