import * as React from 'react';
import { InlineField, InlineFieldRow, InlineLabel, InputControl, Select } from '@grafana/ui';

import { AppContext } from '../../SimplePanel';
import { useFormPublicSettings } from '../../../hooks/useFormPublicSettings';
import { SubSectionSchedulingsActivationProps } from '../../../types/form';

export const SubSectionAmbientPeriod: React.FunctionComponent<SubSectionSchedulingsActivationProps> = ({
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
  control,
  setIsMainModalOpen,
  handlePeriodChange,
}) => {
  const { dico, user } = React.useContext(AppContext);
  const { HOURS, MINUTES, SECONDS } = useFormPublicSettings(setIsMainModalOpen);

  // Translations
  const { fieldAmbientPeriod } = dico?.dico || {};

  return (
    <InlineFieldRow width={'100%'}>
      <InlineField
        label={fieldAmbientPeriod}
        labelWidth={24}
        htmlFor="ambientHours"
        invalid={!!errors.ambient_hours}
        error={errors.ambient_hours?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={HOURS}
              inputId="ambientHours"
              invalid={invalid}
              onChange={(e) =>
                handlePeriodChange(
                  e.value,
                  setValue,
                  getValues,
                  setError,
                  clearErrors,
                  'ambient_hours',
                  'prediction_hours'
                )
              }
              width={12}
            />
          )}
          control={control}
          name="ambient_hours"
        />
      </InlineField>
      <InlineField shrink>
        <InlineLabel>h</InlineLabel>
      </InlineField>
      <InlineField
        labelWidth={24}
        htmlFor="ambientMinutes"
        invalid={!!errors.ambient_minutes}
        error={errors.ambient_minutes?.message}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              options={MINUTES}
              inputId="ambientMinutes"
              onChange={(e) =>
                handlePeriodChange(
                  e.value,
                  setValue,
                  getValues,
                  setError,
                  clearErrors,
                  'ambient_minutes',
                  'prediction_minutes'
                )
              }
              width={12}
              invalid={invalid}
            />
          )}
          control={control}
          name="ambient_minutes"
        />
      </InlineField>
      <InlineField shrink>
        <InlineLabel>min</InlineLabel>
      </InlineField>
      {user.orgRole === 'Admin' && (
        <>
          <InlineField
            labelWidth={24}
            htmlFor="ambientSeconds"
            invalid={!!errors.ambient_seconds}
            error={errors.ambient_seconds?.message}>
            <InputControl
              render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                <Select
                  {...field}
                  options={SECONDS}
                  inputId="ambientSeconds"
                  onChange={(e) =>
                    handlePeriodChange(
                      e.value,
                      setValue,
                      getValues,
                      setError,
                      clearErrors,
                      'ambient_seconds',
                      'prediction_seconds'
                    )
                  }
                  width={12}
                  invalid={invalid}
                />
              )}
              control={control}
              name="ambient_seconds"
            />
          </InlineField>
          <InlineField shrink>
            <InlineLabel>s</InlineLabel>
          </InlineField>
        </>
      )}
    </InlineFieldRow>
  );
};
