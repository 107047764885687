export const MICRO_MAX_FREQ = 80000;
export const ACCELERO_MAX_FREQ = 2000;
export const MIN_INTERVAL_ACCEL = 10;
export const MIN_INTERVAL_MICRO = 50;
export const MAX_CUTOFF_VALUE = 1000;

export const PUBLIC_SETTINGS = 'Public Settings Backup';
export const SETTINGS_VERSION = 'Settings Version';
export const PRIVATE_SETTINGS = 'Private Settings Backup';
export const SIGNATURE_SETTINGS = 'Signature Settings';
export const BATTERY_SETTINGS = 'Battery';
export const SPECTRUM_ZOOM_DATA = 'Signature Spectrum Zoom';

export const INFLUX_DB = 'asystom_db';
export const DASH_GEN = 'DashGen';

export const MIN_BEACON_VERSION_FFT = 4.34;
export const MIN_BEACON_VERSION_CUST_SPECTROGRAM = 4.45;
export const DEPRECATED_VERSION = 4.11;

export const PRIVATE_SETTINGS_LENGTH = 76;
export const PUBLIC_SETTINGS_LENGTH = 20;
export const FFT_SETTINGS_LENGTH = 52;
